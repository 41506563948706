// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Highlights from 'components/Contentful/Highlights'
import ImageAndWords from 'components/Contentful/ImageAndWords'
import Knowledge from 'components/Contentful/Knowledge'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Wärmepumpe Preis /Kosten  Luft-Wasser-Wärmepumpe "
        meta={[
          { name: 'description', content: 'So viel kostet eine Wärmepumpe oder eine Luft-Wasser-Wärmepumpe. - Die Preise der Wärmepumpen sind abhängig von der Ausgangslage. Altbau - Neubau, Ablösung einer Ölheizung usw.' },
          { name: 'keywords', content: '' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='jOT6amvDgWUMUm0MmuISO-5WK8eSECeQoouMiMSiC8q8'
          id='was-kostet-eine-waermepumpe'
        >
          <Highlights
            {...{
    "type": "highlights",
    "id": "5WK8eSECeQoouMiMSiC8q8",
    "updatedAt": "2019-03-20T12:59:06.484Z",
    "title": "Was kostet eine Wärmepumpe?",
    "description": "<p>Der Ersatz einer bestehenden Heizung enthält verschiedene Teile. Gemeinsam mit ihnen klären wir ab, was benötigt wird. Typische Fragen hierzu sind: \"Wie wird das Warmwasser aufbereitet?\" oder \"Wie heiss werden die Radiatoren?\". Unsere Angebote beinhalten alles bis und mit fertiger Installation und Inbetriebnahme. Sie müssen sich um nichts kümmern, wir übernehmen die gesamte Planung, Ausführung und Koordination für Sie.</p>\n",
    "highlights": [
        {
            "type": "highlight",
            "id": "3gqStSqMGI8a8SCYCacyoE",
            "updatedAt": "2019-03-20T12:55:41.694Z",
            "title": "Material",
            "description": "Gerade beim Material ist auf die Qualität zu achten. Wir empfehlen ihnen was von welchem Hersteller am besten passt. Denn standardisierte, einfache Systeme funktionieren am besten.",
            "link": {
                "type": "link",
                "id": "6vZINcwLx6em6UwCoSSIIQ",
                "updatedAt": "2019-03-20T12:23:32.096Z",
                "label": "Kostenlose Offerte",
                "url": "https://www.heizungsmacher.ch/offerte"
            }
        },
        {
            "type": "highlight",
            "id": "5AnRPwmlCokgasC2cOokco",
            "updatedAt": "2019-03-20T12:56:21.273Z",
            "title": "Installation",
            "description": "Zusammen mit unseren regionalen Installationspartnern führen wir die Installation durch. Das Angebot beinhaltet alle nötigen Handwerker für den Umbau.",
            "link": {
                "type": "link",
                "id": "6vZINcwLx6em6UwCoSSIIQ",
                "updatedAt": "2019-03-20T12:23:32.096Z",
                "label": "Kostenlose Offerte",
                "url": "https://www.heizungsmacher.ch/offerte"
            }
        },
        {
            "type": "highlight",
            "id": "44TlVWc57iG0WIMS6KCUuE",
            "updatedAt": "2019-03-20T12:56:56.233Z",
            "title": "Planung & Bewilligung",
            "description": "Jede neue Wärmepumpe in der Schweiz benötigt eine Bewilligung. Die Anforderungen sind von Gemeinde zu Gemeinde und von Kanton zu Kanton unterschiedlich. Das haben wir im Griff und erstellen alle nötigen Unterlagen.",
            "link": {
                "type": "link",
                "id": "6vZINcwLx6em6UwCoSSIIQ",
                "updatedAt": "2019-03-20T12:23:32.096Z",
                "label": "Kostenlose Offerte",
                "url": "https://www.heizungsmacher.ch/offerte"
            }
        },
        {
            "type": "highlight",
            "id": "2rLlP0YyGkcO2OioWogYAy",
            "updatedAt": "2019-03-20T12:57:19.899Z",
            "title": "Nebenarbeiten",
            "description": "Falls nebst dem Heizungsmonteur, dem Elektriker oder dem Isolateur weitere Handwerker benötigt werden, um zum Beispiel ein Fundament zu erstellen, sind diese mit im Angebot.",
            "link": {
                "type": "link",
                "id": "6vZINcwLx6em6UwCoSSIIQ",
                "updatedAt": "2019-03-20T12:23:32.096Z",
                "label": "Kostenlose Offerte",
                "url": "https://www.heizungsmacher.ch/offerte"
            }
        }
    ],
    "anchorName": "was-kostet-eine-waermepumpe"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='jOT6amvDgWUMUm0MmuISO-zikNmKm5kySUQMe6Uu6YA'
          id='Beispiel'
        >
          <ImageAndWords
            {...{
    "type": "imageAndWords",
    "id": "zikNmKm5kySUQMe6Uu6YA",
    "updatedAt": "2023-08-15T09:31:41.485Z",
    "title": "Ein Preisbeispiel",
    "text": "<p>Für ein typisches Einfamilienhaus in der Schweiz mit einer Ölheizung, Radiatoren und einem normalen Warmwasserverbrauch. Die neuen Hauptkomponenten der Heizung sind:</p>\n<p><em>-STIEBEL ELTRON WPL 15 Wärmepumpe (aussenaufgestellt und leistungsgeregelt)</em>\n<em>-STIEBEL ELTRON Kombispeicher SBS 601 (Pufferspeicher und Warmwasser im Durchlaufprinzip)</em></p>\n<p>Alle weiteren Komponenten wie zum Beispiel Pumpen, Sicherheitsventil, Expansionsgefäss, usw. werden durch neue ersetzt und auf das System abgestimmt. Der Preisrahmen liegt meistens zwischen 28'000 und 36'000 CHF.</p>\n",
    "image": {
        "description": "Stiebel Eltron Wärmepumpe WPL inkl. Kombispeicher SBS",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/5VQeGPNDNuS2Y0UyUUc0Uy/cbb48181fc37a5a2945539a5e79c4327/Grafik_Stiebel_Set.png"
    },
    "position": true,
    "anchorName": "Beispiel"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='jOT6amvDgWUMUm0MmuISO-6QyrJUDTHOYOWykku8aYcs'
          id='Details'
        >
          <ImageAndWords
            {...{
    "type": "imageAndWords",
    "id": "6QyrJUDTHOYOWykku8aYcs",
    "updatedAt": "2018-04-05T07:15:16.268Z",
    "title": "Die Details entscheiden",
    "text": "<p>Entscheidend sind oft die Nebenarbeiten. Wird der Öltank demontiert oder nur stillgelegt? Braucht es im Tankraum einen Türausschnitt, eine Türe, Licht und Strom? Kann die Wärmepumpe mit einer Konsole installiert werden oder auf einem Fundament?</p>\n",
    "image": {
        "description": "Wärmepumpe Stiebel Eltron WPL vor Einfamilienhaus",
        "contentType": "image/jpeg",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/6HSHxaB4GsmUKsEUagmcKi/8006f328df67ed1de50a58dbed3497cb/20170821_170134.jpg"
    },
    "position": false,
    "anchorName": "Details"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='jOT6amvDgWUMUm0MmuISO-238GtUAyEAUCKS2yMqIcsG'
          id='Tipps'
        >
          <Knowledge
            {...{
    "type": "knowledge",
    "id": "238GtUAyEAUCKS2yMqIcsG",
    "updatedAt": "2018-04-05T07:15:24.633Z",
    "title": "Tipps & Tricks",
    "description": "<p>Ein Heizungsumbau ist ein grosses Projekt - das wissen wir. Deshalb lohnt sich eine gute Planung umso mehr. Unser Ziel ist, dass ihre Wärmepumpe möglichst lange und möglicht effizient läuft - bei gleichzeitig hohem Wärmekomfort.</p>\n",
    "image": {
        "description": "Modernes Einfamilienhaus mit Umschwung",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/6fqGhgC3cW2gq0cMaIcs6K/7d5adbc528fc9624662ab97dffa78099/34249754_l.png"
    },
    "details": [
        {
            "type": "knowledgeDetail",
            "id": "5jnfryVMwoscoYyCEAqUie",
            "updatedAt": "2018-04-04T05:58:53.217Z",
            "description": "<p>Steuerabzug: Die Investition in eine neue Wärmepumpe wie auch in eine Photovoltaikanlage lassen sich zu 100% steuerlich absetzen.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "1isIseJq1uSoS2geAQ26i6",
            "updatedAt": "2018-04-04T06:00:10.576Z",
            "description": "<p>Fördergelder für Wärmepumpen: Fragen sie bei unseren Experten, welche Förderprogramme es in ihrem Kanton oder ihrer Gemeinde gibt.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "18sQxXlcxSo6Smss2gOEkE",
            "updatedAt": "2018-04-04T06:02:08.388Z",
            "description": "<p>Vorbereitungen: Wollen sie die Umbaukosten tief halten? Kein Problem, durch gute Vorarbeiten geht der Umbau zügig voran und das wiederspeigelt sich in den Kosten.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "1GE21JAeFSmySCuAYIo2M2",
            "updatedAt": "2018-04-04T06:04:58.142Z",
            "description": "<p>Schliessen sie die Wärmepumpe ans Internet an. Das ermöglicht ihnen nicht nur den Fernzugriff, sondern es erleichtert uns auch den Service. In dringenden Fällen können wir oder der Hersteller-Service den Notbetrieb aktivieren.</p>\n"
        }
    ],
    "link": {
        "type": "link",
        "id": "4agKOqOuIEESGqmyUYMmue",
        "updatedAt": "2019-03-20T14:57:00.741Z",
        "label": "Heizung planen",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    },
    "imageInBackground": true,
    "anchorName": "Tipps"
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
